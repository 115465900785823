@import '../../../scss/variables';

.root {
  height: 60px;
  padding: 0 20px 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f3f3f3;
}

.title {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  color: #3e3d40;
}

.searchButton {
  margin-right: 16px;
}

.clearButton {
  font-family: $font-family-avenir;
  font-weight: normal;
  padding: 0px 19px;
  color: #0c6fb1;
  
  &:disabled {
    background: $inline-header-disabled-button-background;
    border: solid 1px #afb2b3;
    color: #afb2b3;
  }
}

