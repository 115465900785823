.alert {
  height: auto;
}

.cancelButton {
  margin: 0 12px;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
}

.inputField {
  flex-basis: 50%;
  padding: 0 20px;
}

.formContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #FFF;
  border-radius: 0 0 4px 4px;
  padding: 10px 10px 0;
}

.conflict {
  font-size: 13px;
}
