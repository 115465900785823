// https://getbootstrap.com/docs/4.0/getting-started/theming/#variable-defaults
// https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss

@import '../scss/variables';

/* #region overrides */
// Color system
$body-color: #3e3d40;

// Typography
$font-family-base: Avenir, Sans-Serif;
$font-size-base: 1rem;

// Forms
$input-border-width: 0;
$form-group-margin-bottom: 0;

// Buttons
$btn-font-family: $font-family-base;
$btn-font-size: $font-size-base;
$btn-line-height: 1;

$btn-font-size-sm: $font-size-base;

$btn-font-size-lg: $font-size-base;
$btn-line-height-lg: 1;

$btn-border-width: 1px;

$btn-font-weight: 500;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 3px;
$btn-border-radius-lg: 3px;
$btn-border-radius-sm: 3px;

// spinner overrides
$spinner-width: $spinner-size;
$spinner-height: $spinner-size;
$spinner-border-width: 10px;
/* #endregion overrides */

// Bootstrap
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';

//@import 'node_modules/bootstrap/scss/alert';
//@import 'node_modules/bootstrap/scss/badge';
//@import 'node_modules/bootstrap/scss/breadcrumb';
//@import 'node_modules/bootstrap/scss/button-group';
@import 'node_modules/bootstrap/scss/buttons';
//@import 'node_modules/bootstrap/scss/card';
//@import 'node_modules/bootstrap/scss/carousel';
//@import 'node_modules/bootstrap/scss/close';
//@import 'node_modules/bootstrap/scss/code';
//@import 'node_modules/bootstrap/scss/custom-forms';
//@import 'node_modules/bootstrap/scss/dropdown';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/grid';
//@import 'node_modules/bootstrap/scss/images';
@import 'node_modules/bootstrap/scss/input-group';
//@import 'node_modules/bootstrap/scss/jumbotron';
//@import 'node_modules/bootstrap/scss/list-group';
//@import 'node_modules/bootstrap/scss/media';
//@import 'node_modules/bootstrap/scss/modal';
//@import 'node_modules/bootstrap/scss/nav';
//@import 'node_modules/bootstrap/scss/navbar';
//@import 'node_modules/bootstrap/scss/pagination';
//@import 'node_modules/bootstrap/scss/popover';
//@import 'node_modules/bootstrap/scss/print';
//@import 'node_modules/bootstrap/scss/progress';
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/root';
@import 'node_modules/bootstrap/scss/spinners';
//@import 'node_modules/bootstrap/scss/tables';
//@import 'node_modules/bootstrap/scss/toasts';
//@import 'node_modules/bootstrap/scss/tooltip';
//@import 'node_modules/bootstrap/scss/transitions';
//@import 'node_modules/bootstrap/scss/type';

.btn {
  &.btn-primary {
    background-color: #bfe9fc;
    color: #0c6fb1;
    border-color: #408dc1;

    &:disabled {
      background-color: #f4f4f5;
      color: #b0b2b3;
      border-color: #d0d1d2;
    }

    &:active:not(:disabled) {
      background-color: #408dc1;
      color: #feffff;
      border-color: #408dc1;
    }
  }

  &.btn-outline-secondary {
    background-color: #fff;
    color: #3e3d40;
    border-color: #929497;

    &:disabled {
      background-color: #fff;
      color: #b0b2b3;
      border-color: #d0d1d2;
    }

    &:active:not(:disabled) {
      background-color: #fff;
      color: #408dc1;
      border-color: #408dc1;
    }
  }
}

.btn-lg {
  &.btn-primary,
  &.btn-outline-secondary {
    height: 64px;
    width: 172px;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      border-width: 2px;
    }
  }
}

.btn {
  &:focus,
  &:active,
  &:focus:active {
    box-shadow: none !important;
  }
}
