@import '../../scss/variables';

.inline-element {
  display: inline-block;
}
.error-wrapper {
  position: relative;
  // TODO: temp fix while https://jira.aligntech.com/browse/ITEROBIZ-53699
  z-index: 999;
  .error-text {
    color: #ff0000;
    font-weight: bold;
    font-size: smaller;
    height: 15px;
    margin: 0 32px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.required {
  color: #ff0000;
}

.gender-input-field {
  color: rgb(62, 61, 64);
  font-size: 19px !important;
  padding: 5px 0 0 0;
}

.gender-inline-label {
  line-height: 2.3;
  padding-right: 15px;
  display: inline-block;
  margin: 0;
  font-family: Avenir-Roman, Roboto, 'Helvetica Neue', sans-serif;
  letter-spacing: 0.36px;
}

.gender-inline-input-field {
  border-bottom: solid 1px rgba(0, 0, 0, 0.42);
  font-size: 18px;
  padding: 1px;
}

.gender-inline-input-field {
  border-bottom: solid 1px rgba(0, 0, 0, 0.42);
  font-size: $normal-font-size;
  padding: 2px 0 1px;
}
