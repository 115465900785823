/*
*specific colors only for buttons
*/

$blue-1: #0c68aa;
$blue-2: #448ebf;
$blue-light-1: #b7e7f9;

$gray-light-1: #ccc;
$gray-light-2: #e0e0e0;
$gray-dark-1: #333;

$white-2: #fdfdfd;

@mixin mui-mixin-button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover{
    background-color: darken($background, 5%);
    border-color: darken($border, 12%);
  }
  &:focus{
    background-color: darken($background, 12%);
    border-color: darken($border, 25%);
    &:hover{
      background-color: darken($background, 5%);
    }
  }
  &:active{
    background-color: darken($background, 12%);
    &:focus{
      background-color: darken($background, 17%);
    }
  }

}

@mixin mui-mixin-button-root() {
  text-transform: none;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  height: 32px;
  min-width: 70px;
  padding: 0;
  

  &:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
  };

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: .65;
    background-color: $gray-light-2;
    color: $gray-dark-1;
    border-color: $gray-light-1;
  }
}

.mui-button-root {
  @include mui-mixin-button-root();
}

.mui-button-text-secondary {
  @include mui-mixin-button-variant($blue-1, $blue-light-1, $blue-2);
}

.mui-button-text-primary {
  @include mui-mixin-button-variant($gray-dark-1, $white-2,  $gray-light-1);
}

.mui-button-size-large {
  min-width: 172px;
  height: 64px;
}