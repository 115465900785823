.root {
  height: 53px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f3f3f3;
}

.title {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  color: #3e3d40;
}
