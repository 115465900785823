@import '../../../scss/variables';

/* TODO: use Bootstrap theme */
.input.input {
  height: 32px;
  line-height: 1;
  border: 1px solid $search-field-border-color;
  color: $search-field-color;

  &::placeholder {
    color: $search-field-placeholder-color;
  }

  &:focus {
    border: 1px solid transparent;
    box-shadow: inset 0 0 0 2px $search-field-focus-box-shadow-color;
  }

  &[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 19px;
    width: 19px;
    display: block;
    background-color: #f3f3f3;
    border-radius: 50%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 11px;
  }

  /* HACK: Targets legacy Edge (not Chromium) - can be safely removed when not supporting Edge */
  @supports (-ms-ime-align: auto) {
    &[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: searchfield-cancel-button;
    }
  }
}
