@import '../../../scss/variables';

.mui-form-control-label-root {
  margin: 0;
}

.mui-form-control-label-label {
  font-size: $normal-font-size;
}

