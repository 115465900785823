@import '../../scss/variables';

.error-border {
  border-bottom: 1px solid #FF0000;
}

.regular-border {
  border-bottom: 1px solid rgb(238, 236, 236);
}

.form-group {
  margin: 0 15px 0 3px;
}

.inline-element {
  display: inline-block;
}

.error-wrapper {
  position: relative;
  top: 10px;

  .error-text {
    color: #FF0000;
    font-weight: bold;
    font-size: smaller;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0 !important;
  }
}

.required {
  color: #FF0000;
}

.gender-input-field {
  border-bottom: solid 1px rgba(0, 0, 0, 0.42);
  font-size: $normal-font-size;
  padding: 2px 0 1px
}

.gender-label {
  line-height: 2.2;
  padding-right: 15px;
  margin: 0;
}
