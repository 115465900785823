@import '../../scss/variables';

.content {
  height: 420px;
  padding: 0 32px;
}

@media screen and (max-height: 559px) {
  .content {
    height: 300px;
	}
}

.row {
  outline: 0;
  cursor: pointer;

  &:hover {
    background: $patient-search-row-hover-bg-color;
  }
}

.row.selectedRow {
  background: $patient-search-selected-row-bg-color;
}

.rowIconSvg {
  width: 24px;
  height: 24px;
  fill: $patient-search-icon-color;
}

.highlightedWord {
  font-weight: bold;
  background: none;
}

.cancelButton {
  margin: 0 12px;
}

.footer {
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.29);
}

.headerActionsWrapper {
  display: flex;
}
.refreshButton {
  margin-left: 10px;
  width: 26px;
  height: 22px;
  background-size: contain;
  margin: 5px 0 0 15px;
  background: url('./images/refresh-button.svg') center center no-repeat;
  background-size: contain;
}

.refreshButtonAnimation {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1000ms;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -moz-animation-name: spin;
  -moz-animation-duration: 1000ms;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: ease;
  -ms-animation-name: spin;
  -ms-animation-duration: 1000ms;
  -ms-animation-iteration-count: 1;
  -ms-animation-timing-function: ease;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.searchField {
  min-width: 292px;
}
