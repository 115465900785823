.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 32px;
  height: 32px;
}

.message {
  font-size: 20px;
  margin-top: 6px;
}

.message:empty {
  color: transparent;

  &::before {
    content: 'Placeholder';
  }
}
