@import '../../../scss/variables';

$levelOfLabelFromTop: 16px;

.MuiTextField-root {
  height: 70px;
}

.MuiInputLabel-formControl {
  transform: translate(0, $levelOfLabelFromTop) scale(1);
}

.MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
}

.MuiInputLabel-root.MuiInputLabel-formControl {
  color: $print-text-color;
  font-size: $normal-font-size;

  &.Mui-error.MuiFormLabel-filled,
  &.Mui-error.Mui-focused,
  .MuiInputLabel-asterisk {
    color: rgb(231, 0, 0);
  }
}

.MuiInputBase-root {
  font-size: $normal-font-size;
}

.MuiInputBase-input.Mui-disabled {
  color: #3e3d40;
  opacity: 0.4;;
}
