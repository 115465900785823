html {
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.version {
  pointer-events: none;
  height: 0;
  text-align: center;
  color: transparent;
  cursor: default;
}
