// Colors
$medium-gray: #f0ecec;
$medium-dark-gray: #e0dede;
$dark-gray: #a7a4a4;
$very-dark-gray: #4b4b4b;

// UI Components
$search-field-border-color: #d0d1d2;
$search-field-color: #3e3d40;
$search-field-focus-box-shadow-color: #408dc1;
$search-field-placeholder-color: #9fa1a3;

// Patient Search
$patient-search-icon-color: #718ea9;
$patient-search-selected-row-bg-color: #cceefb;
$patient-search-row-hover-bg-color: #e7e7e9;

// Spinner
$spinner-size: 80px;


// RxApp Colors variables
$main-bg-color: #858689;
$rx-card-background-color: #fff;
$rx-card-title-background-color: #f3f3f3;
$mat-field-color: rgba(0, 0, 0, 0.42);
$mat-blue-light: #9fdff7;
$mat-blue: #408dc1;
$tooth-number: #8fd7f7;
$text-muted-color: #777;
$inline-header-disabled-button-background: #f4f4f5;
$treatment-table-background-color: #f4f4f5;
$unit-type-menu-background-color: #f4f4f5;
$lab-background-color: #f4f4f5;
$unit-type-menu-border-color: #ccc;
$unit-type-arrow-shadow-color: lightgray;
$tooth-editor-teeth-section-background: #e4e4e5;
$tooth-editor-background-color: #fff;
$tooth-editor-border-color: #ccc;
$blue-button-background-color: #5bc0de;
$bridge-warning-background-color: #f2dede;
$error-color: red;
$bridge-warning-text-color: #a94442;
$hover-background: #ddd;
$lab-border-color: #777;
$print-text-color: #3e3d40;

/*********layout-sizes**********/

$layout-mw: 1280px;

/*******************************/

/************fonts**************/
$normal-font-size: 18px;
$small-font-size: 14px;
$doctor-font-size: $normal-font-size;
$text-field-title-size: $small-font-size;
$alert-font-size: $normal-font-size;
$footer-font-size: $normal-font-size;
$rx-card-body-font-size: $normal-font-size;
$rx-tooth-font-size: $normal-font-size;
$rx-card-font-size: 20px;
$dialog-font-size: 18px;
$treatment-table-th: $small-font-size;

/*******************************/

$font-family-avenir: 'Avenir', Roboto, 'Helvetica Neue', sans-serif;